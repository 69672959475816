import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_table = _resolveComponent("el-table")
  const _component_PerformanceViewForm = _resolveComponent("PerformanceViewForm")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_el_table, { data: _ctx.data }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: "资金年度",
          prop: "fundYearIndex"
        }),
        _createVNode(_component_el_table_column, {
          label: "填报日期",
          prop: "createDate"
        }),
        _createVNode(_component_el_table_column, {
          label: "填报状态",
          prop: "status"
        }, {
          default: _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.status == 0 ? "填报中" : item.status == 1 ? "评价中" : "已评价"), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, { label: "明细" }, {
          default: _withCtx(({ row: item }) => [
            _createVNode(_component_el_button, {
              type: "text",
              icon: "el-icon-document",
              onClick: ($event: any) => (_ctx.show(item))
            }, null, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    (_ctx.performaceViewFormSetting.visible)
      ? (_openBlock(), _createBlock(_component_PerformanceViewForm, {
          key: 0,
          setting: _ctx.performaceViewFormSetting
        }, null, 8, ["setting"]))
      : _createCommentVNode("", true)
  ]))
}