import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_table = _resolveComponent("el-table")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_el_table, { data: _ctx.data }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: "序号",
          type: "index",
          align: "center",
          "header-align": "center",
          width: "80"
        }),
        _createVNode(_component_el_table_column, {
          label: "时间",
          prop: "create_Date",
          align: "center",
          "header-align": "center"
        }),
        _createVNode(_component_el_table_column, {
          label: "操作人",
          prop: "create_UserName",
          align: "center",
          "header-align": "center"
        }),
        _createVNode(_component_el_table_column, {
          label: "动作",
          prop: "action_Name",
          align: "center",
          "header-align": "center"
        }),
        _createVNode(_component_el_table_column, {
          label: "状态变动",
          prop: "statusChange",
          align: "center",
          "header-align": "center"
        }, {
          default: _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.fromStatusName != null && item.toStatusName != null ? (item.fromStatus != item.toStatus ?
            `${item.fromStatusName} => ${item.toStatusName}` : item.fromStatusName) : item.fromStatusName ||
            item.toStatusName || ""), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "备注",
          prop: "memo"
        })
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}